import React from 'react';
import logo from './logo.svg';
import Generator from './Generator.js';
import './App.css';


function App() {
  return (
    <div className="App">
        <Generator/>
    </div>
  );
}

export default App;
