import React from 'react';
import './Banner.css';
import bannerlogo from './bannerlogo.svg';


export function Banner() {
  return (
    <div className="banner">
        <img className="logo" src={bannerlogo} alt="logo" />
        <h1>Insulterator</h1>
    </div>
  );
}