import React, { useState } from 'react';
import './Generator.css';


function Speak(insult){
  var msg = new SpeechSynthesisUtterance();
  msg.text = insult;
  window.speechSynthesis.speak(msg);
}


function Generator(){
    const first = ["Lazy","Stupid","Insecure","Idiotic","Slimy","Slutty","Smelly","Pompous","Communist","Dicknose","Pie-Eating","Racist"," Elitist","White Trash","Drug-Loving","Butterface","Tone Deaf","Ugly","Creepy"];
    const second = ["Douche","Ass","Turd","Rectum","Butt","Cock","Shit","Crotch","Bitch","Turd","Prick","Slut","Taint","Fuck","Dick","Boner","Shart","Nut","Sphincter","Cunt","Bollock"];
    const third = ["Pilot","Canoe","Captain","Pirate","Hammer","Knob","Box","Jockey","Nazi","Waffle","Goblin","Blossum","Biscuit","Clown","Socket","Monster","Hound","Dragon","Balloon"];
    const [count, setCount] = useState(0);

    var message = first[(Math.floor(Math.random() * first.length))] + ' ' + second[(Math.floor(Math.random() * second.length))] + ' ' + third[(Math.floor(Math.random() * third.length))]
    console.log(message);
    if ('speechSynthesis' in window) {var isSpeechSynthesisSupported = true}

    return (
      <div className="insult">
        <h1 className="display-1">{message}</h1>
        <button type="button" className="btn btn-primary generate" onClick={() => setCount(count +1)}>Generate Insult</button>
        {isSpeechSynthesisSupported && 
          <button type="button" className="btn btn-primary speak" onClick={() => Speak(message)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-play" viewBox="0 0 16 16">
              <path d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z"></path>
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"></path>
            </svg>
            Speak
          </button>
        }
      </div>
    );
  }

  export default Generator;